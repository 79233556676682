$darkGrey: #707070;
$white: #f4f4f4;
$red: #f95c4f;
$grey: #606060;
$darkBlue: #332c4b;
$darkGrey: #787878;
$phone-min: 320px;
$tablet-min: 768px;
$tablet-max: 1024px;

$desktop-small-min: 1200px;
$desktop-small-max: 1366px;

$desktop-medium-min: 1367px;
$desktop-medium-max: 1600px;

$desktop-large: 1601px;

@mixin mobile {
	@media (min-width: #{$phone-min}) and (max-width: #{$tablet-min - 1px}) {
		@content;
	}
}
@mixin tablet {
	@media (min-width: #{$tablet-min}) and (max-width: #{$tablet-max }) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-large}) {
		@content;
	}
}
@mixin desktopSmall {
	@media (min-width: #{$desktop-small-min}) and (max-width: #{$desktop-small-max }) {
		@content;
	}
}
@mixin desktopMedium {
	@media (min-width: #{$desktop-medium-min}) and (max-width: #{$desktop-medium-max }) {
		@content;
	}
}

@mixin responsive {
	@media (min-width: #{$phone-min}) and (max-width: #{$tablet-max - 1px}) {
		@content;
	}
}
