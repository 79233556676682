@import "../variables.scss";

.login-container {
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	background: #f1f1f1;
	@include desktopSmall {
		@media screen and (-webkit-min-device-pixel-ratio:0) { 
			select,
			textarea,
			input {
			  font-size: 12px;
			}
		  }
	}
	@include desktopMedium {
		@media screen and (-webkit-min-device-pixel-ratio:0) { 
			select,
			textarea,
			input {
			  font-size: 14px;
			}
		  }
	}
	@include desktop {
		@media screen and (-webkit-min-device-pixel-ratio:0) { 
			select,
			textarea,
			input {
			  font-size: 16px;
			}
		  }
	}
	
	@media screen and (-webkit-min-device-pixel-ratio:0) { 
		select,
		textarea,
		input {
		  font-size: 16px;
		}
	  }
	.ant-row-flex-middle {
		height: 80vh;
	}
	.ant-input-affix-wrapper,.ant-row ,.ant-form-item{
		font-size: 16px !important;

	}
	.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled){
		font-size: 16px !important;
	
	}
	.login-form-row {

		box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
		background: white;
		padding: 1rem 2rem 2rem;
		width: 450px !important;
		margin: 0 10px;
		.error-form-item {
			.ant-form-item-control {
				line-height: 20px;
				margin: 5px 0;
			}
		}
	}
	.ant-form-item {
		margin-bottom: 14px;
	}
	.login-form-button {
		width: 100%;
	}
}
