@import "variables";
.contact-container {
	background: $white;
	margin-top: 100px;
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
}
.form-row-container {
	height: 100%;
	padding: 0 0 50px 0;
}
.form-col {
	// top: 100px !important;
}
.contact-form-container {
	padding: 2em;
	background: #f4f4f4;
	float: right;
	bottom: 0;
	right: 0;
	height: 72vh;
	.ant-form {
		top: 100px;
		padding-top: 1.5em;
	}
	.ant-form-item {
		margin-bottom: 0px !important;
	}
	.ant-form-item-control {
		margin: 0px 0 !important;
	}

	h3 {
		color: $grey;
		text-align: right;
		font-size: 2.85em;
		margin-bottom: 0px;
		line-height: 1;
		font-weight: 300;
	}
	h3 span {
		color: #f95c4f;
		font-family: futura-pt, sans-serif;
		font-weight: 300;
	}

	p {
		font-family: neuzeit-grotesk, sans-serif;
		color: $grey;
		font-size: 1em;
		line-height: 1.2;
		font-weight: 300;
		text-align: right;
		margin-bottom: 0em;
		margin-top: 0.1em;
		@include mobile {
			font-size: 1em !important;
		}
	}
	.input-label {
		font-size: 1em;
	}

	.contact-form-item {
		width: 100%;
		margin-bottom: 0px !important;
	}
	.first-name {
		font-size: 1em !important;

		width: 50% !important;
		padding-right: 2%;
	}
	.last-name {
		font-size: 1em !important;

		width: 50% !important;
		padding-left: 2%;
	}
	.ant-radio-wrapper {
		margin-right: 0px;
		font-size: 1em;
		span {
			color: $grey;
			font-size: 1em;
		}
	}
	.ant-radio-wrapper:hover .ant-radio,
	.ant-radio:hover .ant-radio-inner,
	.ant-radio-input:focus + .ant-radio-inner {
		border-color: $red !important;
	}
	.broker {
		margin-top: 10px;
	}
	.broker .ant-radio-group {
		float: right;
		font-family: neuzeit-grotesk, sans-serif;
		color: $grey;
		font-weight: 300;
		.ant-radio-inner {
			background: $white;
		}
	}
	.ant-radio-checked .ant-radio-inner {
		border-color: $red;
	}
	.ant-radio-inner::after {
		background: $red;
	}
	.broker-text {
		margin-right: 10px;
		font-size: 1em;
	}
	.input-row .ant-input {
		border-radius: 0px;
		background: $white;
		outline: none !important;
		border: 0px solid #707070ad;
		border-bottom: 1px solid #707070ad;
		@include mobile {
			font-size: 1em !important;
		}
	}
	// .ant-nput, textarea, select, a { outline: none !important; }

	.input-row .ant-input:hover {
		border: 0px solid #d9d9d9;
		border-bottom: 1px solid #707070ad;
		border-right: 0x solid #707070ad;
		outline: none !important;

	}
	input:focus, textarea {
		outline: none !important;
	}
	
	.ant-input:hover{
		border-right: 0x solid #707070ad !important;
		outline: none !important;


	}
	.ant-input:focus{
		border-right: 0x solid #707070ad !important;
		outline: none !important;


	}

	.ant-form-explain {
		margin-top: -20px !important;
	}
	.has-error{
		background: #f4f4f4;
	}
	.submit-btn {
		margin: 20px 0;
		height: 3em;
		line-height: 1em;
		width: 5.5em;
		float: right;
		color: $darkGrey;
		background: $white;
		border: 1px solid $darkGrey !important;
		border-radius: 0px;
		font-size: 1em;
		@include mobile {
			font-size: 1em !important;
		}
	}
	.submit-btn:hover,
	.submit-btn:focus {
		background: $red !important;
		border-color: $red !important;
	}

	@include tablet {
		padding: 70px 50px 0px 0px;
		width: 100%;
	}
}

//responsive
@include tablet {
	.contact-form-container {
	}
}
@include tablet {
	.terms {
		font-size: 0.7em !important;
	}
}
@include mobile {
	.ant-form {
		h3 {
			font-size: 3em !important;
		}
		padding: 1em;
		width: 100%;
		p {
			font-size: 0.6rem;
		}
		.terms {
			font-size: 0.7em !important;
		}
	}
	.form-row-container {
		height: 100%;
		padding: 0 0 15px 0;
	}
}
@include desktopSmall {
	.contact-form-container {
		height: 507px;
		width: 479px;
		.ant-form,
		.ant-radio-group,
		.ant-form-item {
			font-size: 12px !important;
		}
		.terms {
			font-size: 0.7em !important;
		}
	}
}
@include desktopMedium {
	.contact-form-container {
		height: 550px;
		width: 560px;
		.ant-form,
		.ant-radio-group,
		.ant-form-item {
			font-size: 14px !important;
		}
		.terms {
			font-size: 0.7em !important;
		}
	}
}
@include desktop {
	.contact-form-container {
		height: 602px;
		width: 636px;
		.ant-form,
		.ant-radio-group,
		.ant-form-item {
			font-size: 16px !important;
		}
		.terms {
			font-size: 0.7em !important;
		}
	}
}
