@import "../variables.scss";
.home-page {
	position: relative;
	width: 100%;
	max-width: 1920px;
	height: 100vh;

	background: $white;
	.hidemobile {
		@include responsive {
			display: none;
		}
	}
	.hidedesktop {
		display: none;
	}
	.header-text {
		position: fixed;
		max-width: 1920px;
		width: 100%;
		top: 30%;
		text-align: right;
		color: white;
		/* left: 10%; */
		padding: 2em;
		z-index: 303;
		h1 {
			margin-bottom: 0 !important;
			line-height: 0.9 !important;
			color: white;
			font-family: futura-pt, sans-serif;
			font-weight: 300;
			font-size: 2.85em;
		}
		@include mobile {
			display: none;
			// top: 15%;
			h1 {
				font-size: 2em;
			}
		}
		@include tablet {
			display: none;
			top: 15%;
			h1 {
				font-size: 3em;
			}
		}
	}
	.building-row {
		background: $white;
		z-index: 303;
		// padding-top: 100px;
		height: 100%;
		@include mobile {
			// padding-top: 75px;
		}
	}
	.mainContent {
		top: 0px;
		position: fixed;
		width: 100%;
		max-width: 1920px;
		z-index: 20;
		background: $white;
	}
	.main-content-relative {
		height: 100%;
		top: 0;
		position: relative !important;
	}
	.row-section {
		padding: 0;
		@include mobile {
			padding: 10px;
		}
		@include tablet {
			padding: 30px;
		}
	}
	.secondRow {
		padding: 30px 0px 15px !important;
	}
	.image-col-with-text {
		width: 80%;
		@include responsive {
			width: 100% !important;
		}
	}
	.image-col-description {
		width: 20%;
		p {
			font-size: 1.8em;
			margin-bottom: 0px;
		}
		@include mobile {
			width: 100%;
			p {
				font-size: 1.4em;
				margin: 10px 0 10px;
				width: 95%;
				float: right;
			}
		}
		@include tablet {
			width: 100%;
			p {
				font-size: 1.8em;
				margin: 10px 0 10px;
				width: 80%;
				float: right;
			}
		}
	}
	.image-description-right {
		text-align: right;
	}
}
